<template>
  <div>
    <BaugeldIndex />
  </div>
</template>

<script>
import BaugeldIndex from '../../../wettbewerbsvergleich/src/components/Wettbewerb/Baugeld/BaugeldIndex';
export default {
  name: 'WettbewerbBaugeldIndex',
  components: { BaugeldIndex },
};
</script>

<style lang="css" scoped></style>
