<template>
  <div>
    <div v-if="loadingAllReports.baugeld" class="wettbewerb-loader">
      <v-progress-circular
        indeterminate
        :value="60"
        :color="color"
      ></v-progress-circular>
    </div>
    <Error v-if="error" />
    <div class="baugeld-content" :style="defaultStyle">
      <div class="wettbewerb-header"></div>
      <div>
        <v-row no-gutters style="padding: 0 0 0 32px" class="flex-column">
          <v-col class="pb-3 pl-0">
            <span class="page-name inter">Historische Daten</span>
          </v-col>
          <v-col>
            <h1 class="page-headline">Baugeld</h1>
          </v-col>
          <v-col>
            <p>
              Hier sehen Sie Ihre zuletzt verwendeten Grafiken in
              chronologischer Reihenfolge. Sie können neue Visualisierungen
              erstellen, oder bereits vorhandene anpassen, exportieren oder
              duplizieren. Vergleichen Sie die Konditionsentwicklung von bis zu
              fünf Banken und sehen Sie, wie Ihr Wettbewerb in den letzten
              Wochen, Monaten oder Jahren auf dem Markt agiert hat.
            </p>
          </v-col>
        </v-row>
      </div>
      <Reports
        :all-reports="allReports"
        :action="createNewReport"
        :error="error"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Error from '../../../../../src/components/fmh_components/common/Error/Error';
import Reports from '../common/Reports';

import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'BaugeldIndex',
  components: { Reports, Error },
  data() {
    return {
      color: BASE_BLACK_COLOR,
    };
  },
  computed: {
    ...mapGetters({
      loadingAllReports: 'wettbewerb/loadingAllReports',
      error: 'wettbewerb/error',
      isAuthorized: 'auth/isAuthorized',
      allReports: 'wettbewerb/allReportsBaugeld',
    }),
    defaultStyle() {
      const height = window.innerHeight - 278;
      return {
        'min-height': `${height}px`,
      };
    },
  },
  watch: {
    isAuthorized(val) {
      if (val) {
        this.fetchWettbewerbData(6);
      }
    },
  },
  methods: {
    ...mapActions({
      fetchWettbewerbData: 'wettbewerb/fetchWettbewerbData',
    }),
    createNewReport() {
      this.$router.push('/wettbewerb-baugeld/new-report');
    },
  },
  created() {
    if (this.isAuthorized) {
      if (!this.allReports.length) {
        this.fetchWettbewerbData(6);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.baugeld-content {
  background-color: $main-background;

  [class~='container'] {
    [class~='all-reports'] {
      height: 100% !important;
      [class~='group-iterator'] {
        height: 100%;
        position: relative;
        padding-top: 10px;
        & > div:last-child {
          position: absolute;
          bottom: 10px;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
        }
      }
    }
  }
}
.new-report {
  & > div:nth-last-of-type(1) {
    [class~='create-new-report'] {
      min-height: 100%;
    }
  }
}
</style>
